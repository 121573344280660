import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { createCurriculum, deleteCourse } from '../../utils/api'; // Ensure deleteCourse is imported
import { useAuth } from '../../hooks/useAuth';
import CurriculumModules from './CurriculumModules';
import CircularProgress from '@mui/material/CircularProgress';

interface Errors {
  courseTitle?: string;
  subject?: string;
  grade?: string;
  numModules?: string;
}

export default function CreateCourse() {
  const { authUser } = useAuth();
  const [generate, setGenerate] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [grade, setGrade] = useState('');
  const [numModules, setNumModules] = useState(2);
  const [coursePrompt, setCoursePrompt] = useState('');
  const [curriculum, setCurriculum] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
    }
    if (fileInputRef) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleGenerate = async () => {
    const validationErrors: Errors = {};

    if (!courseTitle) validationErrors.courseTitle = 'Course Title is required';
    if (!subject) validationErrors.subject = 'Subject is required';

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return; // Don't proceed if there are validation errors
    }

    setLoading(true);
    try {
      const userId = authUser?.documentId;
      const formattedGrade = grade === 'Other' ? 'Other' : formatGrade(grade, coursePrompt);

      // Delete existing course if it exists
      if (courseId) {
        await deleteCourse(courseId);
        setCourseId(null); // Reset courseId after deletion
        setCurriculum(null); // Reset curriculum after deletion
      }

      // Create new curriculum
      const response = await createCurriculum(userId, courseTitle, subject, formattedGrade, coursePrompt, numModules, selectedFiles);
      setCurriculum({ ...response });
      setCourseId(response.course_id);
      setGenerate(true);
    } catch (error) {
      console.error('Error creating curriculum:', error);
    } finally {
      setLoading(false);
    }
  };

  const gradeOptions = [
    'Other', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade',
    '6th Grade', '7th Grade', '8th Grade', '9th Grade', '10th Grade',
    '11th Grade', '12th Grade', 'Adult Education', 'Career Technical Education - Nurses', 'Career Technical Education - Auto-mechanics', 'Career Technical Education - Cooking for Chef', 'Undergrad', 'Grad'
  ];

  const formatGrade = (grade, withGrade) => {
    if (grade === 'Other' || grade === 'Adult Education'  || grade.includes('Technical') || grade === 'Undergrad' || grade === 'Grad') {
      return grade;
    }

    const gradeSuffixes = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      default: 'th'
    };

    const gradeNumber = parseInt(grade);
    const suffix = gradeSuffixes[gradeNumber] || gradeSuffixes.default;
    return withGrade ? `${gradeNumber}${suffix} Grade` : `${gradeNumber}${suffix}`;
  };

  return (
    <Box width="100%" sx={{scrollBehavior: 'smooth'}}>
      <div style={{ width: '100%' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#EFEFEF', py: 1, width: '100%', marginBottom: '20px' }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Create New Course
          </Typography>
        </Box>
      </div>

      <Typography variant="h6" gutterBottom>
        1: Course Information
      </Typography>
      <Box width="100%" px={2} py={2}>
        <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 'bold' }}>Course Title</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={courseTitle}
          onChange={(e) => setCourseTitle(e.target.value)}
          sx={{ flex: 2, marginBottom: '20px' }}
          required
          error={!!errors.courseTitle}
          helperText={errors.courseTitle}
        />
        <Box component="form" noValidate autoComplete="off">
          <Box mb={2} display="flex" gap={1} alignItems="flex-start">
            <Box sx={{ flex: 2 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 'bold' }}>Subject</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                error={!!errors.subject}
                helperText={errors.subject}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 'bold' }}>Grade</Typography>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                >
                  {gradeOptions.map((gradeOption) => (
                    <MenuItem key={gradeOption} value={gradeOption}>
                      {gradeOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 'bold' }}>Modules</Typography>
              <FormControl fullWidth variant="outlined">
                <Select
                  value={numModules}
                  onChange={(e) => setNumModules(Number(e.target.value))}
                >
                  {[2, 4].map((num) => (
                    <MenuItem key={num} value={num}>{num}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <input
                accept="*"
                id="file-upload"
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <label htmlFor="file-upload">
                <Button
                  sx={{
                    flex: 1,
                    maxWidth: '150px',
                    fontWeight: 400,
                    backgroundColor: 'black !important',
                    color: 'white',
                    width: '250px',
                    marginTop: '33px'
                  }}
                  startIcon={<InsertDriveFileIcon />}
                  component="span"
                >
                  Add File
                </Button>
              </label>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle1" sx={{ marginBottom: '5px', fontWeight: 'bold' }}>Course Prompt</Typography>
        <Typography variant="subtitle1" sx={{ marginBottom: '5px' }}>Example: Create a curriculum for advanced Math for 6th grade that includes Number Sense, Measurements, Geometry and Spatial Sense, patterning and probability</Typography>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Additional Course Details"
            multiline
            rows={4}
            variant="outlined"
            value={coursePrompt}
            onChange={(e) => setCoursePrompt(e.target.value)}
            sx={{ marginTop: '10px' }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Box  display="flex" flexWrap='wrap' flexDirection='column' justifyContent="space-between">
            {selectedFiles && selectedFiles.map((file,i) => (
              <Box display="flex" alignItems="center" gap={1} key={i}>
                <InsertDriveFileIcon />
                <Typography variant="body2">{file.name}</Typography>
                <IconButton onClick={() => handleRemoveFile(i)} sx={{ color: 'red' }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box flex="1" />
          <Box flex='none'>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleGenerate}
              startIcon={loading? <CircularProgress size={22}/> : <AutoAwesomeIcon />}
              disabled={loading}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: '#8ad5f8 !important',
                },
              }}
            >
              {loading ? 'Generating...' : 'Generate Curriculum'}
            </Button>
          </Box>
        </Box>
      </Box>
      {generate && curriculum && <CurriculumModules curriculum={curriculum} courseId={courseId} />}
    </Box>
  );
}
